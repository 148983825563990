html {
    scroll-behavior: smooth;
    font-family: 'IBM Plex Sans', sans-serif;
}

.navbar-item .icon {
    display: none;
}

li {
    list-style: none;
}

@media screen and (min-width: 1024px) {
    .navbar-item-link-name {
        display: none;
    }
    .navbar-item .icon {
        display: flex;
    }
}